(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/skeleton/assets/javascripts/skeleton-circle.js') >= 0) return;  svs.modules.push('/components/lb-ui/skeleton/assets/javascripts/skeleton-circle.js');
"use strict";


const SkeletonCircle = _ref => {
  let {
    className,
    size,
    style
  } = _ref;
  const circleStyle = {
    height: size,
    width: size
  };
  let classNames = 'skeleton skeleton-circle';
  if (className) {
    classNames = classNames.concat(' ', className);
  }
  return React.createElement("div", {
    className: "skeletons",
    style: style
  }, React.createElement("div", {
    className: classNames,
    style: circleStyle
  }, '\u00a0'));
};
SkeletonCircle.defaultProps = {
  className: undefined,
  size: 50
};
svs.components.lbUi.SkeletonCircle = SkeletonCircle;

 })(window);