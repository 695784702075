(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/skeleton/assets/javascripts/skeleton.js') >= 0) return;  svs.modules.push('/components/lb-ui/skeleton/assets/javascripts/skeleton.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  SkeletonLine
} = svs.components.lbUi;
const Skeleton = _ref => {
  let {
    height = 1,
    numLines = 1,
    numCols = 1,
    width,
    className,
    style
  } = _ref;
  const skeletonStyles = _objectSpread({}, style);
  if (width > 0) {
    skeletonStyles.maxWidth = width;
  }
  numLines = numLines > 0 ? numLines : 1;
  numCols = numCols > 0 ? numCols : 1;
  const skeletonBaseClassNames = ['padding-1'];
  const skeletonCount = numLines * numCols;
  const hasGrid = numCols > 1;
  const toRender = [];
  for (let i = 0; i < skeletonCount; i++) {
    const skeletonClassNames = [...skeletonBaseClassNames];
    if (hasGrid) {
      const colPos = (i + 1) % numCols;
      if (colPos === 1) {
        skeletonClassNames.push('grid-left');
      } else if (colPos === 0) {
        skeletonClassNames.push('grid-right');
      } else {
        skeletonClassNames.push('grid-center');
      }
    }
    toRender.push(React.createElement(SkeletonLine, {
      className: skeletonClassNames.join(' '),
      height: height,
      key: i,
      style: skeletonStyles
    }));
  }
  const skeletonWrapperClassNames = ['skeletons'];
  if (className) {
    skeletonWrapperClassNames.push(className);
  }
  const skeletonWrapperStyles = {};
  if (hasGrid) {
    skeletonWrapperClassNames.push('skeletons-grid');
    skeletonWrapperStyles['gridTemplateColumns'] = Array(numCols).fill('1fr').join(' ');
  }
  return React.createElement("div", {
    className: skeletonWrapperClassNames.join(' '),
    style: skeletonWrapperStyles
  }, toRender);
};
svs.components.lbUi.Skeleton = Skeleton;

 })(window);