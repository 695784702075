(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/skeleton/assets/javascripts/skeleton-line.js') >= 0) return;  svs.modules.push('/components/lb-ui/skeleton/assets/javascripts/skeleton-line.js');
"use strict";

const SkeletonLine = _ref => {
  let {
    height = 1,
    style,
    className
  } = _ref;
  const classNames = ['skeleton'];
  if (className) {
    classNames.push(className);
  }
  const content = Array(height).fill('\u00a0').join('\n');
  return React.createElement("div", {
    className: classNames.join(' '),
    "data-testid": "skeleton",
    style: style
  }, content);
};
svs.components.lbUi.SkeletonLine = SkeletonLine;

 })(window);